import { Col, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import './style.css';

const CustomModal = ({ show, onDelete, handleShow }) => {
    const [inShow, setInShow] = useState(false);

    const handleClose = () => {
        setInShow(false);
        handleShow();
    };
    useEffect(() => {
        setInShow(show);

    }, [show])

    return (
        <>
            <Modal show={inShow} onHide={handleClose} centered>
                <Row>
                    <Col sm={12}>
                        <div className="modal-box">
                            <h3>Excluir dados</h3>
                            <p>Deseja mesmo realizar esta exclusão?</p>
                            <div className="modal-buttons">
                                <button onClick={onDelete} className="btnYellow">CONTINUAR</button>
                                <button className="btnBorder" onClick={handleClose}>VOLTAR</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

export default CustomModal;