import { Col, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

const ResentModal = ({ show, onResend, onClose }) => {

    return (
        <>
            <Modal show={show} onHide={onClose} centered>
                <Row>
                    <Col sm={12}>
                        <div className="modal-box">
                            <h3>Notificação reenviada</h3>
                            <p>Sua notificação foi reenviada com sucesso.</p>
                            <div className="modal-buttons">
                                {/* <button onClick={onResend} className="btnYellow">REENVIAR</button> */}
                                <button className="btnBorder" onClick={onClose}>VOLTAR</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

export default ResentModal;