import React, { useEffect, useState } from 'react';

import { Col, Row, Form } from 'react-bootstrap';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useAlert } from 'react-alert';
import { readFile } from '../../useful/helpers';
import CropperModal from '../../components/CropperModal';

const NovaConsultasTerapias = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [show, setShow] = useState(false);
    const [imageCrop, setImageCrop] = useState(null);

    const history = useHistory();

    const alert = useAlert();

    useEffect(() => {
        setFormData(new FormData());
    }, []);

    const handleTitle = e => setTitle(e.target.value);
    const handleDescription = e => setDescription(e.target.value);
    const handleImage = async e => {
        setImage(e.target.value);
        let imageDataUrl = await readFile(e.target.files[0]);
        setImageCrop(imageDataUrl);
        setShow(true);
    }


    const handleSubmit = async () => {
        setLoad(true);
        if (thumbnail === null) {
            alert.show("A imagem é obrigatória para prosseguir.");
            setLoad(false);
            return;
        }
        formData.append('title', title);
        formData.append('description', description);
        formData.append('image', thumbnail);

        try {
            const { status } = await api.post('admin/appointment', formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados adicionados com sucesso!");
                history.push("/consultas-e-terapias");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }

    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Consultas e Terapias</h5>
                            <Link to="/consultas-e-terapias">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" name="title" value={title} onChange={handleTitle} placeholder="Inserir aqui" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control as="textarea" rows={6} name="description" value={description} onChange={handleDescription} placeholder="Inserir aqui" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.File label="Faça upload da imagem (ideal: 1.000 x 1.000 px)" name="image" value={image} onChange={handleImage} />
                                    </Form.Group>
                                    {imageCrop &&
                                        <CropperModal
                                            show={show}
                                            image={imageCrop}
                                            setCroppedImage={setThumbnail}
                                        />
                                    }
                                    <img src={thumbnail} className="thumbnail" />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <hr className="hrBorder" />
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default NovaConsultasTerapias;