import { useEffect, useMemo, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { BsArrowUpDown } from "react-icons/bs";

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};


const TableCustomer = ({ customers, setChecked, checked, resource, resourceId, type }) => {
    const { items, requestSort, sortConfig } = useSortableData(customers);
    const [updateChecked, setUpdateChecked] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const selectAll = () => {
        setIsAllChecked(!isAllChecked);
        let inChecked = checked;
        customers.forEach((c) => {
            inChecked[c.id] = !isAllChecked;
        });
        setChecked(inChecked);
    }

    const changeChecks = (customerId) => {
        let c = checked;

        let count = 0;
        let total = 0;

        c[customerId] = !c[customerId];
        setUpdateChecked(!updateChecked);

        c.forEach((item) => {
            if (item === true) count++;
            total++;
        });
        setIsAllChecked(count === total)
        setChecked(c);
    }
    return (
        <Table className="showFor" responsive>
            <thead>
                <tr>
                    <th><Form.Check type="checkbox" onChange={selectAll} checked={isAllChecked} /></th>
                    <th
                        onClick={() => requestSort('name')}
                        className={"tableLeft " + getClassNamesFor('name')}
                    >
                        Usuário <BsArrowUpDown style={{ marginLeft: 10 }} />
                    </th>
                    <th
                        onClick={() => requestSort('original_signature')}
                        className={getClassNamesFor('original_signature')}
                    >
                        Plano <BsArrowUpDown style={{ marginLeft: 10 }} />
                    </th>
                    <th
                        onClick={() => requestSort('original_role')}
                        className={getClassNamesFor('original_role')}
                    >
                        Tipo de membro <BsArrowUpDown style={{ marginLeft: 10 }} />
                    </th>
                    {type === 'edit' && resource === 'assistance' &&
                        <th>Confirmado </th>
                    }
                </tr>
            </thead>
            <tbody>
                {items.map((item) => (
                    <tr key={item.id}>
                        <td><Form.Check type="checkbox" checked={checked[item.id]} onChange={() => changeChecks(item.id)} /></td>
                        <td className="tableLeft">{item.name}</td>
                        <td>{item.original_signature}</td>
                        <td>{item.original_role}</td>
                        {type === 'edit' && resource === 'assistance' &&
                            <td>{item.links.assistanceConfirmed.includes( parseInt(resourceId) ) ? 'Sim' : '-'}</td>
                        }
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
export default TableCustomer;