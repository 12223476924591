import { Col, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

const ConfirmResendModal = ({ show, onResend, onClose }) => {

    return (
        <>
            <Modal show={show} onHide={onClose} centered>
                <Row>
                    <Col sm={12}>
                        <div className="modal-box">
                            <h3>Reenviar notificação</h3>
                            <p>Deseja mesmo reenviar esta notificação?</p>
                            <div className="modal-buttons">
                                <button onClick={onResend} className="btnYellow">REENVIAR</button>
                                <button className="btnBorder" onClick={onClose}>VOLTAR</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

export default ConfirmResendModal;