import React, { useEffect, useState } from 'react';

import { Col, Row, Form } from 'react-bootstrap';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useAlert } from 'react-alert';
const NovoIPDCast = () => {
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [sendNotification, setSendNotification] = useState(false);
    const [soundcloud, setSoundcloud] = useState('');
    const [vimeo, setVimeo] = useState('');
    const [text, setText] = useState('');
    const [tags, setTags] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);

    const history = useHistory();

    const alert = useAlert();

    useEffect(() => {
        setFormData(new FormData());
    }, []);

    const handleTitle = e => setTitle(e.target.value);
    const handleSubtitle = e => setSubtitle(e.target.value);
    const handleText = e => setText(e.target.value);
    const handleSoundcloud = e => setSoundcloud(e.target.value);
    const handleVimeo = e => setVimeo(e.target.value);
    const handleTags = e => setTags(e.target.value);

    const handleSubmit = async () => {
        setLoad(true);
        formData.append('title', title);
        formData.append('subtitle', subtitle);
        formData.append('soundcloud', soundcloud);
        formData.append('vimeo', vimeo);
        formData.append('text', text);
        formData.append('tags', tags);
        if (sendNotification === true) {
            formData.append('sendNotification', 'true');
        }


        try {
            const { status } = await api.post('admin/ipd', formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados adicionados com sucesso!");
                history.push("/ipd-cast");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }
    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>IPD Cast</h5>
                            <Link to="/ipd-cast">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="title" value={title} onChange={handleTitle} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Texto</Form.Label>
                                        <Form.Control as="textarea" rows={12} placeholder="Inserir aqui" name="text" value={text} onChange={handleText} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Subtítulo</Form.Label>
                                        <Form.Control type="text" placeholder="Exemplo: IPD Cast #31" name="subtitle" value={subtitle} onChange={handleSubtitle} />
                                    </Form.Group>
                                    {/* <Form.Group>
                    <Form.Label>URL Soundcloud</Form.Label>
                    <Form.Control type="text" placeholder="Inserir aqui" name="soundcloud" value={soundcloud} onChange={handleSoundcloud} />
                  </Form.Group> */}
                                    <Form.Group>
                                        <Form.Label>Link do vídeo no Youtube</Form.Label>
                                        <Form.Control type="text" placeholder="Ex: https://www.youtube.com/watch?v=sxDjAmxtahs" name="vimeo" value={vimeo} onChange={handleVimeo} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Tags (separadas por vírgula)</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="tags" value={tags} onChange={handleTags} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                        <form className="formShoot">
                            <Form.Check type="checkbox" label="Disparar notificação" value={sendNotification} onChange={() => setSendNotification(!sendNotification)} />
                        </form>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
export default NovoIPDCast;
