import React, { useEffect, useState } from 'react';

import CustomModal from '../../components/CustomModal';
import { Col, Row, Form, Table } from 'react-bootstrap';
import editar from '../../assets/images/edit.png';
import excluir from '../../assets/images/delete.png';
import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { useAlert } from 'react-alert';
import { readFile } from '../../useful/helpers';
import CropperModal from '../../components/CropperModal';

const EditMacumba = () => {
    const [showCrop, setShowCrop] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [thumbnail, setThumbnail] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [image, setImage] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);
    const [idDelete, setIdDelete] = useState(null);
    const [imageCrop, setImageCrop] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);
    const [locations, setLocations] = useState([]);

    const history = useHistory();
    const { id } = useParams();
    const alert = useAlert();

    useEffect(() => {
        const getMacumba = async () => {
            try {
                const { status, data } = await api.get(`admin/macumba/${id}`);
                if (status === 200) {
                    setName(data.resource.name);
                    setDescription(data.resource.description);
                    setThumbnail(data.resource.url_thumbnail);
                    setTags(data.resource.tags);
                    setLocations(data.resource.locations);
                } else {
                    history.push("/consultas-e-terapias");
                }
            } catch (error) {
                console.log(error.response);
            }
        }
        getMacumba();
        setFormData(new FormData());
    }, []);


    const handleName = e => setName(e.target.value);
    const handleDescription = e => setDescription(e.target.value);
    const handleTags = e => setTags(e.target.value);
    const handleImage = async e => {
        setImage(e.target.value);
        let imageDataUrl = await readFile(e.target.files[0]);
        setImageCrop(imageDataUrl);
        setShowCrop(true);
    }
    const handleShowDelete = () => setShowDelete(false);

    const handleThumbnail = (value) => {
        setThumbnail(value);
        setUploadImage(value);
    }

    const handleDelete = async id => {
        setShowDelete(true);
        setIdDelete(id);
    }

    const finishDelete = async () => {
        try {
            const { data: { resources }
            } = await api.delete(`admin/location/${idDelete}`);
            setLocations(resources.data);
            alert.show("Dados removidos com sucesso!");
        } catch (error) {
            console.log(error);
        }
        setShowDelete(false);
    }

    const handleSubmit = async () => {
        setLoad(true);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('tags', tags);
        if (uploadImage !== null) {
            formData.append('image', uploadImage);
        }
        formData.append('_method', 'put');

        try {
            const { status } = await api.post(`admin/macumba/${id}`, formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados atualizados com sucesso!");
                history.push("/rota-macumba");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }
    return (
        <div className="container-fluid">
            <CustomModal show={showDelete} handleShow={handleShowDelete} onDelete={finishDelete} />
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Macumbas</h5>
                            <Link to="/rota-macumba">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="name" value={name} onChange={handleName} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Tags</Form.Label>
                                        <Form.Control type="text" placeholder="Ex: https://urlexterna.com.br" name="tags" value={tags} onChange={handleTags} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control as="textarea" rows={6} placeholder="Inserir aqui" name="description" value={description} onChange={handleDescription} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.File label="Faça upload da imagem (ideal: 1.000 x 1.000 px)" name="image" value={image} onChange={handleImage} />
                                    </Form.Group>
                                    {imageCrop &&
                                        <CropperModal
                                            show={showCrop}
                                            image={imageCrop}
                                            setCroppedImage={handleThumbnail}
                                        />
                                    }
                                    <img src={thumbnail} className="thumbnail" alt={name} />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                {/* <hr className="hrBorder" /> */}
                <Row>
                    <Col sm={12}>
                        <div className="subTitlePages">
                            <h5>Lista de localizações</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Link to={`/rota-macumba/${id}/localizacao/novo`}>
                            <button className="btnNewLocation">NOVA LOCALIZAÇÃO</button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        {load &&
                            <div className="load-table">
                                <div className="loading"></div>
                            </div>
                        }
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Endereço</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    <th>Editar</th>
                                    <th>Excluir</th>
                                </tr>
                            </thead>
                            <tbody>
                                {locations.map((location, key) => (
                                    <tr key={key}>
                                        <td className="tableLeft">{location.name}</td>
                                        <td>{location.address}</td>
                                        <td>{location.lat}</td>
                                        <td>{location.long}</td>
                                        <td>
                                            <Link to={`/rota-macumba/${id}/localizacao/edit/${location.id}`}>
                                                <img className="imgEdit" src={editar} alt="Icon" />
                                            </Link>
                                        </td>
                                        <td>
                                            <img className="imgDelete" src={excluir} alt="Icon" onClick={() => handleDelete(location.id)} />
                                        </td>
                                    </tr>
                                ))}
                                {locations.length === 0 && 
                                    <tr>
                                        <td colSpan={6}>Nenhuma localização cadastrada</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default EditMacumba;