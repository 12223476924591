import React, { useEffect, useState } from 'react';

import { Col, Row, Form } from 'react-bootstrap';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import api from '../../services/api';
import { cepMask, phoneMask, priceMask } from '../../useful/masks';

const EditColaboradores = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [birthday, setBirthday] = useState('');
    const [cep, setCep] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [signature, setSignature] = useState('');
    const [price, setPrice] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [roleId, setRoleId] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);
    const [priceReadonly, setPriceReadonly] = useState(false);

    const history = useHistory();

    const alert = useAlert();
    const { id } = useParams();

    useEffect(() => {

        const getAppointment = async () => {
            try {
                const { status, data } = await api.get(`admin/customer/${id}`);
                if (status === 200) {
                    setName(data.resource.name);
                    setEmail(data.resource.email);
                    setCellphone(phoneMask(data.resource.cellphone));
                    setBirthday(data.resource.birthday);
                    setCep(data.resource.cep);
                    setStreet(data.resource.street);
                    setPriceReadonly(!(data.resource.signature === 'orixa'));
                    setNumber(data.resource.number);
                    setComplement(data.resource.complement);
                    setDistrict(data.resource.district);
                    setCity(data.resource.city);
                    setState(data.resource.state);
                    setSignature(data.resource.signature);
                    setPrice(data.resource.formated_price);
                    setPaymentMethod(data.resource.payment_method);
                    setRoleId(data.resource.role_id);

                } else {
                    history.push("/consultas-e-terapias");
                }
            } catch (error) {
                console.log('Error GetAppointment', error);
            }
        }
        getAppointment();
        setFormData(new FormData());
    }, []);

    const handleName = e => setName(e.target.value);
    const handleEmail = e => setEmail(e.target.value);
    const handleCellphone = e => setCellphone(phoneMask(e.target.value));
    const handleBirthday = e => setBirthday(e.target.value);
    const handlePassword = e => setPassword(e.target.value);
    const handlePasswordConfirmation = e => setPasswordConfirmation(e.target.value);
    const handleStreet = e => setStreet(e.target.value);
    const handleNumber = e => setNumber(e.target.value);
    const handleComplement = e => setComplement(e.target.value);
    const handleDistrict = e => setDistrict(e.target.value);
    const handleCity = e => setCity(e.target.value);
    const handleState = e => setState(e.target.value);
    const handleSignature = e => {
        setSignature(e.target.value);
        setPriceReadonly(!(e.target.value === 'orixa'));
        let value = e.target.value;
        if (value === 'free') {
            setPrice('0,00');
        } else if (value === 'prata') {
            setPrice('39,00');
        } else if (value === 'ouro') {
            setPrice('59,00');
        } else if (value === 'diamante') {
            setPrice('99,00');
        } else {
            setPrice('100,00');
        }

    };
    const handlePrice = e => {
        setPrice(priceMask(e.target.value));
    }
    const handlePaymentMethod = e => setPaymentMethod(e.target.value);
    const handleRoleId = e => setRoleId(e.target.value);

    const handleCep = async e => {

        setCep(cepMask(e.target.value));

        let v = e.target.value.replace(/[^a-z0-9]/gi, '');
        if (v.length === 8) {
            try {
                const { data, status } = await api.get(`cep/${e.target.value}`);
                if (status === 200) {
                    setStreet(data.logradouro);
                    setDistrict(data.bairro);
                    setCity(data.localidade);
                    setState(data.uf);
                }
            } catch (error) {
                console.log('Error handleCep', error);
            }
        }

    };


    const handleSubmit = async () => {
        setLoad(true);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('cellphone', cellphone);
        formData.append('birthday', birthday);
        formData.append('cep', cep);
        formData.append('street', street);
        formData.append('number', number);
        formData.append('complement', complement);
        formData.append('district', district);
        formData.append('city', city);
        formData.append('state', state);
        formData.append('signature', signature);
        formData.append('price', price);
        formData.append('payment_method', paymentMethod);
        formData.append('password', password);
        formData.append('password_confirmation', passwordConfirmation);

        formData.append('role_id', roleId);
        formData.append('_method', 'put');


        try {
            const { status } = await api.post(`admin/customer/${id}`, formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados atualizados com sucesso!");
                history.push("/colaboradores");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }
    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Colaboradores</h5>
                            <Link to="/colaboradores">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={3}>
                                    <Form.Group>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="name" value={name} onChange={handleName} />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="usuario@dominio.com.br" name="email" value={email} onChange={handleEmail} />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group>
                                        <Form.Label>Celular</Form.Label>
                                        <Form.Control type="text" placeholder="(00) 00000-0000" name="cellphone" value={cellphone} onChange={handleCellphone} />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group>
                                        <Form.Label>Data de Nascimento</Form.Label>
                                        <Form.Control type="date" max="2025-12-25" step="1" placeholder="00/00/0000" name="birthday" value={birthday} onChange={handleBirthday} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="boxMargin">
                                <Row>
                                    <Col sm={2}>
                                        <Form.Group>
                                            <Form.Label>CEP</Form.Label>
                                            <Form.Control type="text" placeholder="00000-000" name="cep" value={cep} onChange={handleCep} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Group>
                                            <Form.Label>Endereço</Form.Label>
                                            <Form.Control type="text" placeholder="Inserir aqui" name="street" value={street} onChange={handleStreet} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group>
                                            <Form.Label>Número</Form.Label>
                                            <Form.Control type="text" placeholder="Inserir aqui" name="number" value={number} onChange={handleNumber} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group>
                                            <Form.Label>Complemento</Form.Label>
                                            <Form.Control type="text" placeholder="Inserir aqui" name="complement" value={complement} onChange={handleComplement} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group>
                                            <Form.Label>Bairro</Form.Label>
                                            <Form.Control type="text" placeholder="Inserir aqui" name="district" value={district} onChange={handleDistrict} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Cidade</Form.Label>
                                            <Form.Control type="text" placeholder="Inserir aqui" name="city" value={city} onChange={handleCity} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId="selectState" >
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Control as="select" name="state" value={state} onChange={handleState}>
                                                <option disabled value="">UF</option>
                                                <option value="AC">AC</option>
                                                <option value="AL">AL</option>
                                                <option value="AP">AP</option>
                                                <option value="AM">AM</option>
                                                <option value="BA">BA</option>
                                                <option value="CE">CE</option>
                                                <option value="DF">DF</option>
                                                <option value="ES">ES</option>
                                                <option value="GO">GO</option>
                                                <option value="MA">MA</option>
                                                <option value="MT">MT</option>
                                                <option value="MS">MS</option>
                                                <option value="MG">MG</option>
                                                <option value="PA">PA</option>
                                                <option value="PB">PB</option>
                                                <option value="PR">PR</option>
                                                <option value="PE">PE</option>
                                                <option value="PI">PI</option>
                                                <option value="RJ">RJ </option>
                                                <option value="RN">RN</option>
                                                <option value="RS">RS</option>
                                                <option value="RO">RO</option>
                                                <option value="RR">RR</option>
                                                <option value="SC">SC</option>
                                                <option value="SP">SP</option>
                                                <option value="SE">SE</option>
                                                <option value="TO">TO</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className="boxMargin">
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId="selectPlan">
                                            <Form.Label>Plano</Form.Label>
                                            <Form.Control as="select" name="signature" value={signature} onChange={handleSignature}>
                                                <option value="" disabled>Selecione..</option>
                                                <option value="free">Free</option>
                                                <option value="prata">Prata</option>
                                                <option value="ouro">Ouro</option>
                                                <option value="diamante">Diamante</option>
                                                <option value="orixa">Orixá</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group>
                                            <Form.Label>Valor mensal</Form.Label>
                                            <Form.Control type="text" placeholder="R$00,00" name="price" value={price} onChange={handlePrice} readOnly={priceReadonly} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId="selectPay">
                                            <Form.Label>Forma de pagamento</Form.Label>
                                            <Form.Control as="select" name="paymentMethod" value={paymentMethod} onChange={handlePaymentMethod} disabled>
                                                <option value="" disabled>Selecione..</option>
                                                <option value="paypal">PayPal</option>
                                                <option value="pix">Pix/Boleto</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId="typeMember">
                                            <Form.Label>Tipo de membro</Form.Label>
                                            <Form.Control as="select" name="roleId" value={roleId} onChange={handleRoleId}>
                                                <option value="" disabled>Selecione..</option>
                                                <option value="medium">Médiuns</option>
                                                <option value="consulente">Consulentes</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Senha <small>(Digite a senha somente se desejar atualizá-la)</small></Form.Label>
                                            <Form.Control type="password" name="price" value={password} onChange={handlePassword} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Confirmação de senha</Form.Label>
                                            <Form.Control type="password" name="price" value={passwordConfirmation} onChange={handlePasswordConfirmation} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <hr className="hrBorder" />
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
export default EditColaboradores;