import React, { useEffect, useState } from 'react';

import { Col, Row, Form, Table } from 'react-bootstrap';
import editar from '../../assets/images/edit.png';
import excluir from '../../assets/images/delete.png';
import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { useAlert } from 'react-alert';
import { readFile } from '../../useful/helpers';
import CropperModal from '../../components/CropperModal';

const EditMacumba = () => {
  const [thumbnail, setThumbnail] = useState('');
  const [name, setName] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [address, setAddress] = useState('');
  const [formData, setFormData] = useState(null);
  const [load, setLoad] = useState(false);

  const history = useHistory();
  const { id, macumba } = useParams();
  const alert = useAlert();

  useEffect(() => {
    const getLocation = async () => {
      try {
        const { status, data } = await api.get(`admin/location/${id}`);
        console.log(data);
        if (status === 200) {
          setName(data.resource.name);
          setLat(data.resource.lat);
          setLong(data.resource.long);
          setAddress(data.resource.address);
        } else {
          history.push(`/rota-macumba/edit/${macumba}`);
        }
      } catch (error) {
        console.log(error.response);
      }
    }
    getLocation();
    setFormData(new FormData());
  }, []);


  const handleName = e => setName(e.target.value);
  const handleLong = e => setLong(e.target.value);
  const handleLat = e => setLat(e.target.value);
  const handleAddress = e => setAddress(e.target.value);

  const handleSubmit = async () => {
    setLoad(true);
    formData.append('name', name);
    formData.append('lat', lat);
    formData.append('long', long);
    formData.append('image', thumbnail);
    formData.append('address', address);
    formData.append('macumba_id', macumba);
    formData.append('_method', 'put');

    try {
      const { status } = await api.post(`admin/location/${id}`, formData);
      setLoad(false);
      if (status === 201) {
        alert.show("Dados atualizados com sucesso!");
        history.push(`/rota-macumba/edit/${macumba}`);
      }
    } catch ({ response }) {
      setLoad(false);
      const { status, data } = response;
      if (status === 401) {
        for (let item in data.message) {
          alert.show(data.message[item][0]);
        }
      }
    }

  }
  return (
    <div className="container-fluid">
      {load &&
        <div className="load">
          <div className="loading"></div>
        </div>
      }
      <Menu />
      <div className="content">
        <Row>
          <Col sm={12} className="col-title">
            <div className="titlePages">
              <h5>Localização</h5>
              <Link to={`/rota-macumba/edit/${macumba}`}>
                <button className="btnBorder">VOLTAR</button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form className="formNew">
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type="text" placeholder="Inserir aqui" name="name" value={name} onChange={handleName} />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Endereço</Form.Label>
                    <Form.Control type="text" placeholder="Inserir aqui" name="address" value={address} onChange={handleAddress} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control type="text" placeholder="Inserir aqui" name="name" value={lat} onChange={handleLat} />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control type="text" placeholder="Inserir aqui" name="address" value={long} onChange={handleLong} />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <hr className="hrBorder" />
        <Row>
          <Col sm={6}>
            <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EditMacumba;