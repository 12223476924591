import api from "./api";

export const adminIsAuthenticated = () => localStorage.getItem('@ipd:adminToken') !== null;

export const adminGetToken = () => localStorage.getItem('@ipd:adminToken');

export const setAdmin = (admin, token) => {
    localStorage.setItem('@ipd:admin', JSON.stringify(admin));
    localStorage.setItem('@ipd:adminToken', token);
}

export const getAdmin = () => {
    let admin = localStorage.getItem('@ipd:admin');

    if (admin === null) {
        return admin;
    }
    return JSON.parse(admin);
}

export const validatedToken = async () => {
    const admin = getAdmin();
    const token = adminGetToken();
    if (admin !== null) {
        try {
            const response = await api.post(`admin/validate`, {
                admin,
                token
            });
            if (response.status === 200) {
                return response.data.success
            }
        } catch (error) {
            console.log(error.response);
            return false;
        }
    }
    return false;
}
export const adminLogout = () => {
    localStorage.removeItem('@ipd:admin');
    localStorage.removeItem('@ipd:adminToken');
    window.location.href = "/";
};

export default adminIsAuthenticated;