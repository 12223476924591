import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './style.css';

import { Link } from 'react-router-dom';

import icon1 from '../../assets/images/menu/icon1.png';
import icon2 from '../../assets/images/menu/icon2.png';
import icon3 from '../../assets/images/menu/icon3.png';
import icon4 from '../../assets/images/menu/icon4.png';
import icon5 from '../../assets/images/menu/icon5.png';
import icon6 from '../../assets/images/menu/icon6.png';
import icon7 from '../../assets/images/menu/icon7.png';
import icon8 from '../../assets/images/menu/icon8.png';
import icon9 from '../../assets/images/menu/icon9.png';
import icon10 from '../../assets/images/menu/icon10.png';

export const Menu = () => {

    const location = useLocation();
    const current = location.pathname;

    return (
        <div id="mainMenu">
            <ul>
                <NavLink to="/gira">
                    <li>
                        <img src={icon1} alt="Icon" /><p>Giras e Rezas</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/pontos-cantados">
                    <li>
                        <img src={icon2} alt="Icon" /><p>Pontos cantados</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/ipd-cast">
                    <li>
                        <img src={icon3} alt="Icon" /><p>IPDCast</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/cursos-e-eventos">
                    <li>
                        <img src={icon4} alt="Icon" /><p>Cursos e Eventos</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/consultas-e-terapias">
                    <li>
                        <img src={icon5} alt="Icon" /><p>Consultas e Terapias</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/parceiro">
                    <li>
                        <img src={icon6} alt="Icon" /><p>Parceiros</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/rota-macumba">
                    <li>
                        <img src={icon9} alt="Icon" /><p>Rota de macumba</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/biblioteca">
                    <li >
                        <img src={icon10} alt="Icon" /><p>Biblioteca</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/colaboradores">
                    <li>
                        <img src={icon7} alt="Icon" /><p>Colaboradores</p>
                        <hr />
                    </li>
                </NavLink>
                <NavLink to="/notificacao">
                    <li>
                        <img src={icon8} alt="Icon" /><p>Notificações</p>
                        <hr />
                    </li>
                </NavLink>
            </ul>
        </div>
    );
}

export default Menu;