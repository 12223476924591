import React, { useEffect, useState } from 'react';

import { Col, Row, Form, Table } from 'react-bootstrap';
import TableCustomer from '../../components/TableCustomer';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { useAlert } from 'react-alert';
import { readFile } from '../../useful/helpers';
import CropperModal from '../../components/CropperModal';

const EditCursosEventos = () => {

    const [checked, setChecked] = useState([]);
    const [uploadImage, setUploadImage] = useState(null);
    const [course, setCourse] = useState([]);
    const [thumbnail, setThumbnail] = useState('');
    const [customers, setCustomers] = useState([]);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);
    const [show, setShow] = useState(false);
    const [imageCrop, setImageCrop] = useState(null);

    const history = useHistory();
    const { id } = useParams();
    const alert = useAlert();

    const getCustomers = async (course) => {
        try {
            const { status, data } = await api.get(`admin/customer`);
            if (status === 200) {
                let c = checked;
                data.resources.data.forEach(customer => {
                    c[customer.id] = course.links.customers.includes(customer.id);
                });
                setChecked(c);
                setCustomers(data.resources.data);
            } else {
                history.push("/cursos-e-eventos");
            }
        } catch (error) {
            console.log(error.response);
        }
    }
    const getCourse = async (onlyCourse = false) => {
        try {
            const { status, data } = await api.get(`admin/course/${id}`);
            if (status === 200) {
                if (!onlyCourse) {

                    setTitle(data.resource.title);
                    setDate(data.resource.links.original_date);
                    setThumbnail(data.resource.url_thumbnail);
                    setHour(data.resource.hour);
                    setDescription(data.resource.description);
                }
                setCourse(data.resource);
                getCustomers(data.resource);
            } else {
                history.push("/cursos-e-eventos");
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    useEffect(() => {
        getCourse();
        setFormData(new FormData());
    }, []);


    const handleTitle = e => setTitle(e.target.value);
    const handleDate = e => setDate(e.target.value);
    const handleHour = e => setHour(e.target.value);
    const handleDescription = e => setDescription(e.target.value);
    const handleImage = async e => {
        setImage(e.target.value);
        let imageDataUrl = await readFile(e.target.files[0]);
        setImageCrop(imageDataUrl);
        setShow(true);
    }
    const handleThumbnail = (value) => {
        setThumbnail(value);
        setUploadImage(value);
    }


    const handleSubmit = async () => {
        setLoad(true);
        formData.append('title', title);
        formData.append('date', date);
        formData.append('hour', hour);

        if (uploadImage !== null) {
            formData.append('image', uploadImage);
        }
        formData.append('description', description);
        formData.append('checked', JSON.stringify(checked));
        formData.append('_method', 'put');

        try {
            const { status } = await api.post(`admin/course/${id}`, formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados atualizados com sucesso!");
                history.push("/cursos-e-eventos");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }
    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12}>
                        <div className="titlePages col-title">
                            <h5>Cursos e Eventos</h5>
                            <Link to="/cursos-e-eventos">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="title" value={title} onChange={handleTitle} />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group>
                                        <Form.Label>Dia</Form.Label>
                                        <Form.Control type="date" name="date" value={date} max="2025-12-25" step="1" onChange={handleDate} />
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group>
                                        <Form.Label>Horário</Form.Label>
                                        <Form.Control type="time" name="hour" value={hour} onChange={handleHour} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control as="textarea" rows={6} placeholder="Descrição" name="description" value={description} onChange={handleDescription} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.File label="Faça upload da imagem (ideal: 1.000 x 1.000 px)" name="image" value={image} onChange={handleImage} />
                                    </Form.Group>
                                    {imageCrop &&
                                        <CropperModal
                                            show={show}
                                            image={imageCrop}
                                            setCroppedImage={handleThumbnail}
                                        />
                                    }
                                    <img src={thumbnail} className="thumbnail" alt={title} />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="subTitlePages">
                            <h5>Exibir para</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <TableCustomer
                            customers={customers}
                            setChecked={setChecked}
                            checked={checked}
                        />
                    </Col>
                </Row>
                <hr className="hrBorder" />
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
export default EditCursosEventos;
