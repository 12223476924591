import React, { useEffect, useState } from 'react';

import { Modal, Table, Col, Row, Form } from 'react-bootstrap';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { positions, useAlert } from 'react-alert';
import CropperModal from '../../components/CropperModal';
import { readFile } from '../../useful/helpers';

import editar from '../../assets/images/edit.png';
import excluir from '../../assets/images/delete.png';

const CreateEditModal = (props) => {
    const [name, setName] = useState('');
    const [lat, setLat] = useState('');
    const [long, setLong] = useState('');
    const [address, setAddress] = useState('');

    const handleName = e => setName(e.target.value);
    const handleAddress = e => setAddress(e.target.value);
    const handleLat = e => setLat(e.target.value);
    const handleLong = e => setLong(e.target.value);

    useEffect(() => {
        if(props.locations[props.positionToEdit]){
            setName(props.locations[props.positionToEdit]?.name);
            setLat(props.locations[props.positionToEdit]?.lat);
            setLong(props.locations[props.positionToEdit]?.long);
            setAddress(props.locations[props.positionToEdit]?.address);
        } else {
            setName('');
            setLat('');
            setLong('');
            setAddress('');
        }
    }, [props.positionToEdit, props.locations]);


    const handleSave = () => {
        let data = { name, lat, long, address };
        props.onSave(data);
        props.onClose();
    }
    
    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Body>

            <Row>
                <Col sm={12}>
                    <div className="modal-box">
                        <h3>Localização</h3>
                        <Form className="formNew">
                            <Form.Group>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" placeholder="Inserir aqui" name="name" value={name} onChange={handleName} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Endereço</Form.Label>
                                <Form.Control type="text" placeholder="Inserir aqui" name="address" value={address} onChange={handleAddress} />
                            </Form.Group>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="name" value={lat} onChange={handleLat} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Longitude</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="address" value={long} onChange={handleLong} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <div className="modal-buttons">
                            <button onClick={handleSave} className="btnYellow">SALVAR</button>
                            <button className="btnBorder" onClick={props.onClose}>VOLTAR</button>
                        </div>
                    </div>
                </Col>
            </Row>
            </Modal.Body>
        </Modal>
    )
}

const NovaMacumba = () => {
    const [name, setName] = useState('');
    const [sendNotification, setSendNotification] = useState(false);
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [image, setImage] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [show, setShow] = useState(false);
    const [imageCrop, setImageCrop] = useState(null);

    const [locations, setLocations] = useState([]);
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [positionToEdit, setPositionToEdit] = useState(0);

    const alert = useAlert();
    const history = useHistory();

    useEffect(() => {
        setFormData(new FormData());
    }, []);


    const handleName = e => setName(e.target.value);
    const handleDescription = e => setDescription(e.target.value);
    const handleTags = e => setTags(e.target.value);
    const handleImage = async e => {
        setImage(e.target.value);
        let imageDataUrl = await readFile(e.target.files[0]);
        setImageCrop(imageDataUrl);
        setShow(true);
    }


    const handleSubmit = async () => {
        setLoad(true);
        if (thumbnail === null) {
            alert.show("A imagem é obrigatória para prosseguir.");
            setLoad(false);
            return;
        }
        formData.append('name', name);
        formData.append('description', description);
        formData.append('image', thumbnail);
        formData.append('tags', tags);
        formData.append('locations', JSON.stringify(locations));
        
        if (sendNotification) {
            formData.append('sendNotification', 'true');
        }

        try {
            const { status } = await api.post('admin/macumba', formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados adicionados com sucesso!");
                history.push("/rota-macumba");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }

    const handleAddLocation = () => {
        let position = locations.length;
        setPositionToEdit(position);
        setShowCreateEdit(true);
    }

    const handleDelete = (position) => {
        let toLocations = [];
        locations.map((l, key) => {
            if(key !== position){
                toLocations.push(l);
            }
        });
        setLocations(toLocations);
    }

    const saveLocations = (data) => {
        let toLocations = [...locations];
        toLocations[positionToEdit] = data;
        setLocations(toLocations);
    }

    const handleEditLocation = (e, position) => {
        e.preventDefault();
        setPositionToEdit(position);
        setShowCreateEdit(true);
    }

    return (
        <div className="container-fluid">
            <CreateEditModal 
                show={showCreateEdit} 
                onClose={() => setShowCreateEdit(false)} 
                onSave={(data) => saveLocations(data)}
                positionToEdit={positionToEdit}
                locations={locations}
            />
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Rotas de Macumba</h5>
                            <Link to="/rota-macumba">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="name" value={name} onChange={handleName} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Tags</Form.Label>
                                        <Form.Control type="text" placeholder="Ex: eucalipto, planta" name="tags" value={tags} onChange={handleTags} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control as="textarea" rows={6} placeholder="Inserir aqui" name="description" value={description} onChange={handleDescription} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.File label="Faça upload da imagem (ideal: 1.000 x 1.000 px)" name="image" value={image} onChange={handleImage} />
                                    </Form.Group>
                                    {imageCrop &&
                                        <CropperModal
                                            show={show}
                                            image={imageCrop}
                                            setCroppedImage={setThumbnail}
                                        />
                                    }
                                    <img src={thumbnail} className="thumbnail" />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                {/* <hr className="hrBorder" /> */}
                <Row>
                    <Col sm={12}>
                        <div className="subTitlePages">
                            <h5>Lista de localizações</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <a href="#">
                            <button className="btnNewLocation" onClick={handleAddLocation}>NOVA LOCALIZAÇÃO</button>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        {load &&
                            <div className="load-table">
                                <div className="loading"></div>
                            </div>
                        }
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Endereço</th>
                                    <th>Latitude</th>
                                    <th>Longitude</th>
                                    <th>Editar</th>
                                    <th>Excluir</th>
                                </tr>
                            </thead>
                            <tbody>
                                {locations.map((location, key) => (
                                    <tr key={key}>
                                        <td className="tableLeft">{location.name}</td>
                                        <td>{location.address}</td>
                                        <td>{location.lat}</td>
                                        <td>{location.long}</td>
                                        <td>
                                            <Link to={`#`} onClick={(e) => handleEditLocation(e, key)}>
                                                <img className="imgEdit" src={editar} alt="Icon" />
                                            </Link>
                                        </td>
                                        <td>
                                            <img className="imgDelete" src={excluir} alt="Icon" onClick={() => handleDelete(key)} />
                                        </td>
                                    </tr>
                                ))}
                                {locations.length === 0 && 
                                    <tr>
                                        <td colSpan={6}>Nenhuma localização cadastrada</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                        <form className="formShoot">
                            <Form.Check type="checkbox" label="Disparar notificação" value={sendNotification} onChange={() => setSendNotification(!sendNotification)} />
                        </form>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default NovaMacumba;