import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import './styles.css';

import logo from '../../assets/images/logo2.png';
import api from '../../services/api'
import { setAdmin } from '../../services/auth';
import { useHistory } from 'react-router';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [load, setLoad] = useState(false);

  const history = useHistory();
  const alert = useAlert();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const validation = validate();
      if (!validation) return;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);

      const { data, status } = await api.post(`admin/login`, formData);
      setLoad(false);
      if (status === 201) {
        setAdmin(data.admin, data.token);
        window.location.href = "/gira"
        // history.push('gira');
      } else {
        alert.show("Usuário ou senha incorretos.");
      }
    } catch ({ response }) {
      const { status, data } = response;
      console.log(status, data);
      setLoad(false);
      if (status === 401) {
        alert.show("Usuário ou senha incorretos.");
      }
      if (status === 500) {
        alert.show("Ops, tivemos problemas no servidor, fale com um administrador.");
      }
    }
  }
  const validate = () => {
    if (email === '') {
      alert.show("E-mail é obrigatório");
      setLoad(false);
      return false;
    }
    if (password === '') {
      setLoad(false);
      alert.show("Senha é obrigatório");
      return false;
    }
    return true;
  }
  return (
    <>
      <div className="boxLogo">
        <img src={logo} alt="Logo" />
        <h3>Bem vindo</h3>
      </div>
      <div className="contentLogin">
        <div className="container-fluid">

          <h3>Login</h3>
          <form>
            <Row>
              <Col sm={12}>
                <label className="inputFloating">
                  <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder=" " />
                  <span>E-MAIL</span>
                </label>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <label className="inputFloating">
                  <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder=" " />
                  <span>SENHA</span>
                </label>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Link to="reset/password">ESQUECI A MINHA SENHA</Link>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <button className="btnYellow" type="button" onClick={handleSubmit} disabled={load}>ENTRAR</button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}
export default Login;