import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { positions, Provider, transitions, types } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Routes from './routes';

import './style.css';

const options = {
  timeout: 3000,
  type: types.INFO,
  transition: transitions.FADE,
  position: positions.BOTTOM_RIGHT,

};

const App = () => (
  <Provider template={AlertTemplate} {...options}>
    <Routes />
  </Provider >
);

export default App;
