import React, { useEffect, useState } from 'react';

import { Col, Row, Form } from 'react-bootstrap';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import { useAlert } from 'react-alert';

const NovoPontosCantados = () => {
    const [title, setTitle] = useState('');
    const [soundcloud, setSoundcloud] = useState('');
    const [vimeo, setVimeo] = useState('');
    const [lyrics, setLyrics] = useState('');
    const [tags, setTags] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);


    const history = useHistory();
    const { id } = useParams();
    const alert = useAlert();

    useEffect(() => {
        const getSing = async () => {
            try {
                const { status, data } = await api.get(`admin/sing/${id}`);
                if (status === 200) {
                    setTitle(data.resource.title);
                    setSoundcloud(data.resource.soundcloud);
                    setVimeo(`https://www.youtube.com/watch?v=${data.resource.vimeo}`);
                    setLyrics(data.resource.lyrics);
                    setTags(data.resource.tags);
                } else {
                    history.push("/pontos-cantados");
                }
            } catch (error) {
                console.log(error.response);
            }
        }

        getSing();

        setFormData(new FormData());
    }, []);

    const handleTitle = e => setTitle(e.target.value);
    const handleLyrics = e => setLyrics(e.target.value);
    const handleSoundcloud = e => setSoundcloud(e.target.value);
    const handleVimeo = e => setVimeo(e.target.value);
    const handleTags = e => setTags(e.target.value);

    const handleSubmit = async () => {
        setLoad(true);
        formData.append('title', title);
        formData.append('soundcloud', soundcloud);
        formData.append('vimeo', vimeo);
        formData.append('lyrics', lyrics);
        formData.append('tags', tags);
        formData.append('_method', 'put');

        try {
            const { status } = await api.post(`admin/sing/${id}`, formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados atualizados com sucesso!");
                history.push("/pontos-cantados");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }

    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Pontos cantados</h5>
                            <Link to="/pontos-cantados">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" name="title" onChange={handleTitle} value={title} placeholder="Inserir aqui" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Letra</Form.Label>
                                        <Form.Control as="textarea" name="lyrics" onChange={handleLyrics} value={lyrics} rows={12} placeholder="Letra do ponto" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    {/* <Form.Group>
                    <Form.Label>URL Soundcloud</Form.Label>
                    <Form.Control type="text" name="soundcloud" onChange={handleSoundcloud} value={soundcloud} placeholder="Inserir aqui" />
                  </Form.Group> */}
                                    <Form.Group>
                                        <Form.Label>Link do vídeo no Youtube</Form.Label>
                                        <Form.Control type="text" name="vimeo" onChange={handleVimeo} value={vimeo} placeholder="Ex: https://www.youtube.com/watch?v=sxDjAmxtahs" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Tags (separadas por vírgula)</Form.Label>
                                        <Form.Control type="text" name="tags" onChange={handleTags} value={tags} placeholder="Inserir aqui" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default NovoPontosCantados;
