import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Header from './components/Header';
import Login from './pages/Login';

import GiraAtendimento from './pages/GiraAtendimento';
import NovaGira from './pages/GiraAtendimento/create';
import EditGira from './pages/GiraAtendimento/edit';

import PontosCantados from './pages/PontosCantados';
import NovoPontosCantados from './pages/PontosCantados/create';
import EditPontosCantados from './pages/PontosCantados/edit';

import IPDCast from './pages/IPDCast';
import NovoIPDCast from './pages/IPDCast/create';
import EditIPDCast from './pages/IPDCast/edit';

import CursosEventos from './pages/CursosEventos';
import NovosCursosEventos from './pages/CursosEventos/create';
import EditCursosEventos from './pages/CursosEventos/edit';

import ConsultasTerapias from './pages/ConsultasTerapias';
import NovaConsultasTerapias from './pages/ConsultasTerapias/create';
import EditConsultasTerapias from './pages/ConsultasTerapias/edit';

import Parceiros from './pages/Parceiros';
import NovoParceiro from './pages/Parceiros/create';
import EditParceiro from './pages/Parceiros/edit';

import Colaboradores from './pages/Colaboradores';
import NovoColaboradores from './pages/Colaboradores/create';
import EditColaboradores from './pages/Colaboradores/edit';

import Admin from './pages/Admin';
import NovoAdmin from './pages/Admin/create';
import EditAdmin from './pages/Admin/edit';

import Macumbas from './pages/Macumbas';
import NovaMacumba from './pages/Macumbas/create';
import EditMacumba from './pages/Macumbas/edit';

import NovaLocalizacao from './pages/NovaLocalizacao';
import EditLocalizacao from './pages/EditLocalizacao';

import Notificacoes from './pages/Notificacoes';
import NovaNotificacoes from './pages/Notificacoes/create';
import EditNotificacoes from './pages/Notificacoes/edit';

import Biblioteca from './pages/Biblioteca';
import NovaBiblioteca from './pages/Biblioteca/create';
import EditBiblioteca from './pages/Biblioteca/edit';

import ResetPassword from './pages/ResetPassword';

import adminIsAuthenticated from './services/auth';

const Routes = () => {
    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={props =>
                adminIsAuthenticated() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                )
            }
        />
    );


    return (
        <BrowserRouter>
            {(document.location.pathname !== '/' && document.location.pathname !== '/reset/password') &&
                <Header />
            }
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/reset/password" component={ResetPassword} />

                <PrivateRoute exact path="/gira" component={GiraAtendimento} />
                <PrivateRoute exact path="/gira/novo" component={NovaGira} />
                <PrivateRoute exact path="/gira/edit/:id" component={EditGira} />

                <PrivateRoute exact path="/biblioteca" component={Biblioteca} />
                <PrivateRoute exact path="/biblioteca/novo" component={NovaBiblioteca} />
                <PrivateRoute exact path="/biblioteca/edit/:id" component={EditBiblioteca} />

                <PrivateRoute exact path="/pontos-cantados" component={PontosCantados} />
                <PrivateRoute exact path="/pontos-cantados/novo" component={NovoPontosCantados} />
                <PrivateRoute exact path="/pontos-cantados/edit/:id" component={EditPontosCantados} />

                <PrivateRoute exact path="/ipd-cast" component={IPDCast} />
                <PrivateRoute exact path="/ipd-cast/novo" component={NovoIPDCast} />
                <PrivateRoute exact path="/ipd-cast/edit/:id" component={EditIPDCast} />

                <PrivateRoute exact path="/cursos-e-eventos" component={CursosEventos} />
                <PrivateRoute exact path="/cursos-e-eventos/novo" component={NovosCursosEventos} />
                <PrivateRoute exact path="/cursos-e-eventos/edit/:id" component={EditCursosEventos} />

                <PrivateRoute exact path="/consultas-e-terapias" component={ConsultasTerapias} />
                <PrivateRoute exact path="/consultas-e-terapias/novo" component={NovaConsultasTerapias} />
                <PrivateRoute exact path="/consultas-e-terapias/edit/:id" component={EditConsultasTerapias} />

                <PrivateRoute exact path="/rota-macumba" component={Macumbas} />
                <PrivateRoute exact path="/rota-macumba/novo" component={NovaMacumba} />
                <PrivateRoute exact path="/rota-macumba/edit/:id" component={EditMacumba} />
                <PrivateRoute exact path="/rota-macumba/:id/localizacao/novo" component={NovaLocalizacao} />
                <PrivateRoute exact path="/rota-macumba/:macumba/localizacao/edit/:id" component={EditLocalizacao} />

                <PrivateRoute exact path="/parceiro" component={Parceiros} />
                <PrivateRoute exact path="/parceiro/novo" component={NovoParceiro} />
                <PrivateRoute exact path="/parceiro/edit/:id" component={EditParceiro} />

                <PrivateRoute exact path="/colaboradores" component={Colaboradores} />
                <PrivateRoute exact path="/colaboradores/novo" component={NovoColaboradores} />
                <PrivateRoute exact path="/colaboradores/edit/:id" component={EditColaboradores} />

                <PrivateRoute exact path="/admin" component={Admin} />
                <PrivateRoute exact path="/admin/novo" component={NovoAdmin} />
                <PrivateRoute exact path="/admin/edit/:id" component={EditAdmin} />

                <PrivateRoute exact path="/notificacao" component={Notificacoes} />
                <PrivateRoute exact path="/notificacao/novo" component={NovaNotificacoes} />
                <PrivateRoute exact path="/notificacao/edit/:id" component={EditNotificacoes} />

            </Switch>
        </BrowserRouter>
    );
}

export default Routes;