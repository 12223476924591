import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';

import './style.css';

import logo from '../../assets/images/logo.png';
import user from '../../assets/images/user.png';
import logout from '../../assets/images/logout.png';
import /*adminIsAuthenticated,*/ { adminLogout } from '../../services/auth';

export default class Header extends React.Component {

  toggleMenu = () => {
    const menu = document.getElementById('mainMenu');
    const status = menu.style.display === 'block' ? 'none' : 'block';
    menu.style.display = status;
  }
  handleLogout = () => {
    adminLogout();
  }

  render() {
    return (
      <>
        <header>
          <div className="container-fluid">
            <Row>
              <Col>
                <Link to="/gira">
                  <img src={logo} alt="Logo" />
                </Link>
              </Col>
              <Col>
                <div className="optMenu">
                  <a href="#" onClick={this.handleLogout}>
                    <button>
                      <img id="logoutHeader" src={logout} alt="Logout" />
                      <p>Sair</p>
                    </button>
                  </a>
                </div>
                <div className="optMenu">
                  <NavLink to="/admin">
                    <button>
                      <img id="userHeader" src={user} alt="Usuários" />
                      <p>Usuários</p>
                    </button>
                  </NavLink>
                </div>
              </Col>
            </Row>
            <button className="toggleMobile" onClick={this.toggleMenu}>
              <div className="rowToggleMobile"></div>
              <div className="rowToggleMobile"></div>
              <div className="rowToggleMobile"></div>
            </button>
          </div>
        </header>
      </>
    );
  }
}