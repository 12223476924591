import React, { useEffect, useState } from 'react';

import { Col, Row, Form, Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import './styles.css';

import Menu from '../../components/Menu';

import search from '../../assets/images/search.png';
import editar from '../../assets/images/edit.png';
import excluir from '../../assets/images/delete.png';
import api from '../../services/api';
import CustomModal from '../../components/CustomModal';
import { useAlert } from 'react-alert';

const Biblioteca = () => {

  const [bibliotecas, setBibliotecas] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [show, setShow] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [load, setLoad] = useState(false);

  const alert = useAlert();

  const getBibliotecas = async (search = '') => {
    try {
      const { data: { resources }
      } = await api.get('admin/library', { params: { search } });
      setBibliotecas(resources.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error.response);
    }
  }


  useEffect(() => {
    getBibliotecas();
  }, []);

  const handleDelete = async id => {
    setShow(true);
    setIdDelete(id);
  }
  const finishDelete = async () => {
    try {
      const { data: { resources }
      } = await api.delete(`admin/library/${idDelete}`);
      setBibliotecas(resources.data);
      alert.show("Dados removidos com sucesso!");
    } catch (error) {
      console.log(error);
    }
    setShow(false);
  }
  const handleSearch = (e) => {
    const value = e.target.value;
    setLoad(true);
    setSearchString(value);
    getBibliotecas(value);
  }

  const handleShow = () => setShow(false);

  return (
    <div className="container-fluid">
      <CustomModal show={show} handleShow={handleShow} onDelete={finishDelete} />
      <Menu />
      <div className="content">
        <Row>
          <Col sm={12} className="col-title">
            <div className="titlePages">
              <h5>Biblioteca</h5>
              <Link to="/biblioteca/novo">
                <button className="btnYellow">NOVO</button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form className="formSearch">
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="text" placeholder="Buscar" name="search" value={searchString} onChange={handleSearch} />
                <button><img src={search} alt="Icon" /></button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            {load &&
              <div className="load-table">
                <div className="loading"></div>
              </div>
            }
            <Table responsive>
              <thead>
                <tr>
                  <th className="tableLeft">Título</th>
                  <th>Editar</th>
                  <th>Excluir</th>
                </tr>
              </thead>
              <tbody>
                {bibliotecas.map((biblioteca, key) => (
                  <tr key={key}>
                    <td className="tableLeft">{biblioteca.title}</td>
                    <td>
                      <Link to={`/biblioteca/edit/${biblioteca.id}`}>
                        <img className="imgEdit" src={editar} alt="Icon" />
                      </Link>
                    </td>
                    <td><img className="imgDelete" src={excluir} alt="Icon" onClick={() => handleDelete(biblioteca.id)} /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Biblioteca