import { Col, Modal, Row } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import getCroppedImg from './cropImage'

import './style.css';
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';

const CropperModal = ({ show, image, setCroppedImage }) => {
    const [inShow, setInShow] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [aspect] = useState(4 / 4);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const handleClose = () => setInShow(false);
    useEffect(() => {
        setInShow(show);
    }, [show]);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
            )
            setCroppedImage(croppedImage);
            setInShow(!show);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    return (
        <Modal show={inShow} onHide={handleClose} centered>
            <Row>
                <Col sm={12}>
                    <div className="modal-box">
                        <div className="crop-container">
                            <Cropper
                                style={{ 
                                    containerStyle: {
                                        marginTop: 65,
                                        width: 250,
                                        height: 250,
                                    }
                                }}
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspect}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </div>
                        <div className="modal-buttons">
                            <button className="btnYellow" onClick={showCroppedImage}>CORTAR</button>
                            <button className="btnBorder" onClick={handleClose}>VOLTAR</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}

export default CropperModal;