import axios from 'axios';
import { adminGetToken, adminLogout } from './auth';


const production = true;
export const baseURL = production ? 'https://backend.instituto.imaxinformatica.com.br' : 'http://127.0.0.1:8000';

const api = axios.create({
    baseURL: `${baseURL}/api`
});

api.interceptors.request.use(async config => {
    const token = adminGetToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use((response) => {
    return response
}, function (error) {
    if (error.response.status === 401 && window.location.href.indexOf("/") <= -1) { // if the error is 401 and hasent already been retried
        adminLogout();
        console.log(error.response);
    }

    return Promise.reject(error);
})

export default api;