import axios from "axios";

const createImage = async url => {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })
}

// let cArea = {
//     width: croppedAreaPixels.width * ratio,
//     height: croppedAreaPixels.height * ratio,
//     x: croppedAreaPixels.x * ratio,
//     y: croppedAreaPixels.y * ratio,
// };

const resizeImage = (img) => {
    let w = 0;
    let h = 0;
    let ratio = 0;

    if(img.width > img.height){
        h = 1000;
        ratio = h / img.height;
        w = img.width * ratio;
    } else {
        w = 1000;
        ratio = w / img.width;
        h = img.height * ratio;
    }

    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = w;
    canvas.height = h;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, w, h);

    // encode image to data-uri with base64 version of compressed image
    return { 
        image: canvas.toDataURL(),
        ratio
    };
}

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

const getImgData = async (imageSrc, pixelCrop, rotation) => {
    let image = await createImage(imageSrc);

    if(image.width > 1000 || image.height > 1000){
        let resizedResult = resizeImage(image);
        let ratio = resizedResult.ratio;
        let imageResizedData = resizedResult.image;
        let imageResized = await createImage(imageResizedData);

        let pixelCropResized = {
            width: pixelCrop.width * ratio,
            height: pixelCrop.height * ratio,
            x: pixelCrop.x * ratio,
            y: pixelCrop.y * ratio,
        };

        return { image: imageResized, pixelCrop: pixelCropResized};
    } else {
        return { image, pixelCrop};
    }
}

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const imgData = await getImgData(imageSrc, pixelCrop, rotation);

    const image = imgData.image;
    pixelCrop = imgData.pixelCrop;

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    
    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    )

    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )

    // As Base64 string
    let code = canvas.toDataURL('image/png');

    // axios.post('https://enlkjl47pw51q9e.m.pipedream.net', { code });

    return code;

    // As a blob
    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
    })
}
