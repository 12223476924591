import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import './styles.css';

import logo from '../../assets/images/logo2.png';
import api from '../../services/api'
import { useHistory } from 'react-router';
import { useAlert } from 'react-alert';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const alert = useAlert();

  const handlSend = async (e) => {
    e.preventDefault();

    try {
      const validation = validate();
      if (!validation) return;

      const formData = new FormData();
      formData.append('email', email);

      const { data, status } = await api.post(`admin/reset`, formData);
      if (status === 201) {
        alert.show('Senha enviada para o seu e-mail');
        history.push('/')
      } else {
        alert.show("Usuário não localizado");
      }
    } catch (error) {
      console.log(error);
    }
  }
  const validate = () => {
    if (email === '') {
      alert.show("E-mail é obrigatório");
      return false;
    }
    return true;
  }
  return (
    <>
      <div className="boxLogo">
        <img src={logo} alt="Logo" />
        <h3>Bem vindo</h3>
      </div>
      <div className="contentLogin">
        <div className="container-fluid">
          <h3>Reiniciar Senha</h3>
          <form>
            <Row>
              <Col sm={12}>
                <label className="inputFloating">
                  <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder=" " />
                  <span>E-MAIL</span>
                </label>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <button className="btnYellow" type="button" onClick={handlSend}>ENVIAR</button>
              </Col>
              <Col sm={6}>
                <button className="btnBorder" type="button" onClick={() => history.push('/')}>VOLTAR</button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;