import React, { useEffect, useState } from 'react';

import { Col, Row, Form, Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import './styles.css';

import Menu from '../../components/Menu';

import search from '../../assets/images/search.png';
import enviar from '../../assets/images/send.png';
import api from '../../services/api';
import CustomModal from '../../components/CustomModal';
import ConfirmResendModal from '../../components/ConfirmResendModal';
import ResentModal from '../../components/ResentModal';

import editar from '../../assets/images/edit.png';
import excluir from '../../assets/images/delete.png';
import { useAlert } from 'react-alert';

import moment from 'moment';

const Notificacoes = () => {
  const [notifications, setNotifications] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [show, setShow] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [load, setLoad] = useState(false);

  const [showResendConfirm, setShowResendConfirm] = useState(false);
  const [toResend, setToResend] = useState(0);

  const [showResentModal, setShowResentModal] = useState(false);

  const alert = useAlert();

  const getNotifications = async (search = '') => {
    try {
      const { data: { resources }
      } = await api.get('admin/notification', { params: { search } });
      setNotifications(resources.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error.response);
    }
  }
  useEffect(() => {
    getNotifications();
  }, []);

  const resend = () => {
    setShowResendConfirm(false);
    setLoad(true);

    api(`/admin/notification/resend/${toResend}`).then(res => {
      setShowResentModal(true);
      setLoad(false);
    }).catch(err => {
      setLoad(false);
      console.log(err.response);
    })
  }

  const confirmResend = id => {
    console.log('confirmResend' , id)
    setToResend(id);
    setShowResendConfirm(true);
  }

  const handleDelete = async id => {
    setShow(true);
    setIdDelete(id);
  }

  const finishDelete = async () => {
    try {
      const { data: { resources }
      } = await api.delete(`admin/notification/${idDelete}`);
      setNotifications(resources.data);
      alert.show("Dados removidos com sucesso!");
    } catch (error) {
      console.log(error);
    }
    setShow(false);
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchString(value);
    getNotifications(value);
    setLoad(true);
  }

  const handleShow = () => setShow(false);
  return (
    <div className="container-fluid">
      <CustomModal show={show} handleShow={handleShow} onDelete={finishDelete} />
      <ConfirmResendModal show={showResendConfirm} onResend={resend} onClose={() => setShowResendConfirm(false)} />
      <ResentModal show={showResentModal} onClose={() => setShowResentModal(false)} />

      <Menu />
      <div className="content">
        <Row>
          <Col sm={12} className="col-title">
            <div className="titlePages">
              <h5>Notificações</h5>
              <Link to="/notificacao/novo">
                <button className="btnYellow">NOVO</button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form className="formSearch">
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="text" placeholder="Buscar" name="search" value={searchString} onChange={handleSearch} />
                <button><img src={search} alt="Icon" /></button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {load &&
              <div className="load-table">
                <div className="loading"></div>
              </div>
            }
            <Table responsive>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Horário</th>
                  <th className="tableLeft">Título</th>
                  <th>Automático</th>
                  <th>Seção</th>
                  <th>Reenviar</th>
                  <th>Editar</th>
                  <th>Excluir</th>
                </tr>
              </thead>
              <tbody>
                {notifications.map((notification, key) => (
                  <tr key={key}>
                    <td>{notification.date ?? moment(notification.created_at).format('DD/MM/YYYY')}</td>
                    <td>{notification.hour ?? moment(notification.created_at).format('HH:mm')}</td>
                    <td className="tableLeft">{notification.title}</td>
                    <td>{notification.automatic === 1 ? 'Sim' : 'Não'}</td>
                    <td>{notification.type_name}</td>
                    <td>
                      <img className="imgSend" src={enviar} alt="Icon" onClick={() => confirmResend(notification.id)} />
                    </td>
                    <td>
                      {notification.automatic !== 1 &&
                        <Link to={`/notificacao/edit/${notification.id}`}>
                          <img className="imgEdit" src={editar} alt="Icon" />
                        </Link>
                      }
                    </td>
                    <td>
                      <img className="imgDelete" src={excluir} alt="Icon" onClick={() => handleDelete(notification.id)} />
                    </td>
                  </tr>
                ))}


              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div >
  );
}

export default Notificacoes;