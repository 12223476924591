import React, { useEffect, useState } from 'react';

import { Col, Row, Form } from 'react-bootstrap';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import api from '../../services/api';

const EditAdmin = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmationPassword, setConfirmationPassword] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);

    const history = useHistory();

    const alert = useAlert();
    const { id } = useParams();

    useEffect(() => {

        const getAppointment = async () => {
            try {
                const { status, data } = await api.get(`admin/admin/${id}`);
                if (status === 200) {
                    setName(data.resource.name);
                    setEmail(data.resource.email);

                } else {
                    history.push("/consultas-e-terapias");
                }
            } catch (error) {
                console.log(error.response);
            }
        }
        getAppointment();
        setFormData(new FormData());
    }, []);

    const handleName = e => setName(e.target.value);
    const handleEmail = e => setEmail(e.target.value);
    const handlePassword = e => setPassword(e.target.value);
    const handleConfirmationPassword = e => setConfirmationPassword(e.target.value);

    const handleSubmit = async () => {
        setLoad(true);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('password_confirmation', confirmationPassword);
        formData.append('_method', 'put');


        try {
            const { status } = await api.post(`admin/admin/${id}`, formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados atualizados com sucesso!");
                history.push("/admin");
            }
        } catch ({ response }) {
            setLoad(false);
            const { status, data } = response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }
    }
    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Usuários</h5>
                            <Link to="/admin">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" placeholder="Inserir aqui" name="name" value={name} onChange={handleName} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="usuario@dominio.com.br" name="email" value={email} onChange={handleEmail} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Senha (Digite a senha somente se desejar atualizá-la)</Form.Label>
                                        <Form.Control type="password" placeholder="Inserir aqui" name="password" value={password} onChange={handlePassword} />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Confirmação de senha (Digite a senha somente se desejar atualizá-la)</Form.Label>
                                        <Form.Control type="password" placeholder="Inserir aqui" name="password_confirmation" value={confirmationPassword} onChange={handleConfirmationPassword} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <hr className="hrBorder" />
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
export default EditAdmin;