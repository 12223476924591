import React, { useEffect, useState } from 'react';

import { Col, Row, Form, Table } from 'react-bootstrap';
import { BsArrowUpDown } from 'react-icons/bs';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory, useParams } from 'react-router-dom';

import api from '../../services/api';
import { useAlert } from 'react-alert';
import CropperModal from '../../components/CropperModal';
import { readFile } from '../../useful/helpers';
import TableCustomer from '../../components/TableCustomer';

const EditGira = () => {
    const [assistance, setAssistance] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [checked, setChecked] = useState([]);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);
    const [show, setShow] = useState(false);
    const [imageCrop, setImageCrop] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);

    const { id } = useParams();
    const alert = useAlert();
    const history = useHistory();

    const getCustomers = async (assistance) => {
        try {
            const { status, data } = await api.get(`admin/customer`);
            if (status === 200) {
                let c = checked;
                data.resources.data.forEach(customer => {
                    c[customer.id] = assistance.links.customers.includes(customer.id);
                });
                setChecked(c);
                console.log(data.resources.data);
                setCustomers(data.resources.data);
            } else {
                history.push("/gira");
            }
        } catch (error) {
            console.log('Error getCustomers', error.response);
        }
    }
    const getAssistance = async (onlyAssistance = false) => {
        try {
            const { status, data } = await api.get(`admin/assistance/${id}`);
            if (status === 200) {
                if (!onlyAssistance) {
                    setTitle(data.resource.title);
                    setDate(data.resource.links.original_date);
                    setHour(data.resource.hour);
                    setDescription(data.resource.description);
                    setThumbnail(data.resource.url_thumbnail);
                }
                setAssistance(data.resource);
                getCustomers(data.resource);
            } else {
                history.push("/gira");
            }
        } catch (error) {
            console.log('Error getAssistance', error.response);
        }
    }

    useEffect(() => {
        getAssistance();
        setFormData(new FormData());
    }, []);

    const handleTitle = e => setTitle(e.target.value);
    const handleDate = e => setDate(e.target.value);
    const handleHour = e => setHour(e.target.value);
    const handleDescription = e => setDescription(e.target.value);
    const handleImage = async e => {
        setImage(e.target.value);
        let imageDataUrl = await readFile(e.target.files[0]);
        setImageCrop(imageDataUrl);
        setShow(true);
    }
    const handleThumbnail = (value) => {
        setThumbnail(value);
        setUploadImage(value);
    }
    const textPresent = (customer) => {
        if (customer.links.assistanceConfirmed.includes(assistance.id)) {
            return "Sim";
        }
        if (customer.links.assistanceNotConfirmed.includes(assistance.id)) {
            return "Não";
        }
        return "-";
    }


    const handleSubmit = async () => {
        setLoad(true);
        formData.append('title', title);
        formData.append('date', date);
        formData.append('hour', hour);
        if (uploadImage !== null) {
            formData.append('image', uploadImage);
        }
        formData.append('description', description);
        formData.append('checked', JSON.stringify(checked));
        formData.append('_method', 'put');

        try {
            const { status } = await api.post(`admin/assistance/${id}`, formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados atualizados com sucesso!");
                history.push("/gira");
            }
        } catch (error) {
            console.log('Error handleSubmit', error.response);
            setLoad(false);
            const { status, data } = error.response;
            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }

    }
    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Giras e Rezas</h5>
                            <Link to="/gira">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control value={title} name="title" onChange={handleTitle} type="text" placeholder="Inserir aqui" />
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group>
                                        <Form.Label>Dia</Form.Label>
                                        <Form.Control value={date} max="2025-12-25" step="1" onChange={handleDate} type="date" />
                                    </Form.Group>
                                </Col>
                                <Col sm={2}>
                                    <Form.Group>
                                        <Form.Label>Horário</Form.Label>
                                        <Form.Control type="time" value={hour} onChange={handleHour} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Descrição</Form.Label>
                                        <Form.Control as="textarea" value={description} onChange={handleDescription} rows={6} placeholder="Descrição" />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.File label="Faça upload da imagem. (ideal: 1.000 x 1.000 px)" name="image" value={image} onChange={handleImage} />
                                    </Form.Group>
                                    {imageCrop &&
                                        <CropperModal
                                            show={show}
                                            image={imageCrop}
                                            setCroppedImage={handleThumbnail}
                                        />
                                    }
                                    <img src={thumbnail} className="thumbnail" alt={title} />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="subTitlePages">
                            <h5>Exibir para</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <TableCustomer
                            customers={customers}
                            setChecked={setChecked}
                            checked={checked}
                            resource="assistance"
                            resourceId={id}
                            type="edit"
                        />
                    </Col>
                </Row>
                <hr className="hrBorder" />
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" type="button" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div >
    );
}

export default EditGira;
