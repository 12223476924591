import React, { useEffect, useState } from 'react';

import { Col, Row, Form } from 'react-bootstrap';

import './styles.css';

import Menu from '../../components/Menu';
import { Link, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useAlert } from 'react-alert';
import TableCustomer from '../../components/TableCustomer';

const NovaNotificacoes = () => {

    const [customers, setCustomers] = useState([]);
    const [checked, setChecked] = useState([]);
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [text, setText] = useState('');
    const [schedule, setSchedule] = useState('');
    const [date, setDate] = useState('');
    const [hour, setHour] = useState('');
    const [formData, setFormData] = useState(null);
    const [load, setLoad] = useState(false);
    const [showSchedule, setShowSchedule] = useState('none');


    const alert = useAlert();
    const history = useHistory();

    const getCustomers = async () => {
        try {
            const { status, data } = await api.get(`admin/customer`);
            if (status === 200) {
                let c = checked;
                data.resources.data.forEach(customer => {
                    c[customer.id] = false;
                });
                setChecked(c);
                setCustomers(data.resources.data);
            } else {
                history.push("/notificacao");
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    useEffect(() => {

        getCustomers();
        setFormData(new FormData());
    }, []);


    const handleTitle = e => setTitle(e.target.value);
    const handleSubtitle = e => setSubtitle(e.target.value);
    const handleText = e => setText(e.target.value);
    const handleSchedule = e => {
        setSchedule(e.target.value);
        if (e.target.value === 'agendado') {
            setShowSchedule('flex');
        } else {
            setShowSchedule('none');
            setDate('');
            setHour('');
        }
    }
    const handleDate = e => setDate(e.target.value);
    const handleHour = e => setHour(e.target.value);



    const handleSubmit = async () => {
        setLoad(true);
        formData.append('title', title);
        formData.append('subtitle', subtitle);
        formData.append('text', text);
        formData.append('schedule', schedule);
        formData.append('date', date);
        formData.append('hour', hour);
        formData.append('automatic', 0);
        formData.append('checked', JSON.stringify(checked));

        try {
            const { status } = await api.post('admin/notification', formData);
            setLoad(false);
            if (status === 201) {
                alert.show("Dados adicionados com sucesso!");
                history.push("/notificacao");
            }
        } catch (error) {
            console.log(error);
            setLoad(false);
            const { status, data } = error.response;

            if (status === 401) {
                for (let item in data.message) {
                    alert.show(data.message[item][0]);
                }
            }
        }
    }

    return (
        <div className="container-fluid">
            {load &&
                <div className="load">
                    <div className="loading"></div>
                </div>
            }
            <Menu />
            <div className="content">
                <Row>
                    <Col sm={12} className="col-title">
                        <div className="titlePages">
                            <h5>Notificações</h5>
                            <Link to="/notificacao">
                                <button className="btnBorder">VOLTAR</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Form className="formNew">
                            <Row>
                                <Col sm={5}>
                                    <Form.Group>
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" name="title" value={title} onChange={handleTitle} placeholder="Inserir aqui" />
                                    </Form.Group>
                                </Col>
                                <Col sm={5}>
                                    <Form.Group>
                                        <Form.Label>Subtítulo</Form.Label>
                                        <Form.Control type="text" name="subtitle" value={subtitle} onChange={handleSubtitle} placeholder="Inserir aqui" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5}>
                                    <Form.Group>
                                        <Form.Label>Texto</Form.Label>
                                        <Form.Control as="textarea" rows={6} name="text" value={text} onChange={handleText} placeholder="Inserir aqui" />
                                    </Form.Group>
                                </Col>
                                <Col sm={5}>
                                    <Row>
                                        <Col sm={7}>
                                            <Form.Group controlId="selectScheduling" >
                                                <Form.Label>Agendamento</Form.Label>
                                                <Form.Control as="select" name="schedule" value={schedule} onChange={handleSchedule}>
                                                    <option disabled value="">Selecione..</option>
                                                    <option value="agendado">Agendado</option>
                                                    <option value="imediato">Imediato</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: showSchedule }}>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>Dia</Form.Label>
                                                <Form.Control type="date" name="date" value={date} max="2025-12-25" step="1" onChange={handleDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Group>
                                                <Form.Label>Horário</Form.Label>
                                                <Form.Control type="time" name="hour" value={hour} onChange={handleHour} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="subTitlePages">
                            <h5>Enviar para</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={9}>
                        <TableCustomer
                            customers={customers}
                            setChecked={setChecked}
                            checked={checked}
                        />
                    </Col>
                </Row>
                <hr className="hrBorder" />
                <Row>
                    <Col sm={8}>
                        <button className="btnYellow btnFloat" onClick={handleSubmit}>SALVAR</button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default NovaNotificacoes;